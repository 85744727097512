import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as R from 'ramda';

import { Tab } from '@atom/components/common/chipTab/ChipTab';
import TabContainer from '@atom/components/common/chipTab/TabContainer';
import FavoritesListsTab from '@atom/components/common/favorites/FavoritesListsTab';
import { CREATE_FAVORITE, UPDATE_FAVORITE } from '@atom/graph/favorite';
import {
  CREATE_FAVORITES_LIST,
  GET_FAVORITES_LIST_TREE,
} from '@atom/graph/favoritesList';
import { useGroupedPagination } from '@atom/hooks/useGroupedPagination';
import { useInventoryCategoryTree } from '@atom/hooks/useInventoryCategoryTree';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { Snackbar } from '@atom/mui';
import {
  ASSETS_LIMIT,
  FavoriteCreateInput,
  FavoriteDetail,
  FAVORITES_LIMIT,
  FAVORITES_LIST_LIMIT,
  FavoritesList,
  FavoritesListCreateInput,
  FavoritesListsTreeConnectionInput,
  FavoritesListTreeConnection,
  FavoriteSubjectType,
  FavoriteUpdateInput,
  flattenFavoriteData,
} from '@atom/types/favorite';
import {
  InventoryAssetDetailType,
  InventoryCategoryTree,
  SearchAsset,
} from '@atom/types/inventory';
import api from '@atom/utilities/api';
import {
  getCategoryById,
  getCategoryPathNames,
} from '@atom/utilities/categoryUtilities';
import { INVENTORY_ASSETS_ENDPOINT } from '@atom/utilities/endpoints';
import { toggleFromSet } from '@atom/utilities/setUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import InventoryAssetSearch from './InventoryAssetSearch';
import InventoryCategorySearch from './InventoryCategorySearch';
import SearchInput from './SearchInput';
import TreeContext, { Tree, View } from './TreeContext';
import TreeView from './TreeView';

import './inventoryAssetTree.css';

interface Props {
  /**
   * Denotes which category to use as the root of the tree. Defaults to
   * inventory.
   */
  rootCategory?: { id: string; name: string; categoryPath: string[] };
  /**
   * Denotes whether the tree should return only folders with published schema
   * associated to them. Used in category tree API call
   */
  onlyPublishedCategories?: boolean;
  /**
   * Denotes whether the tree should render assets per category or
   * simply the category tree. Default is true.
   */
  includeAssets?: boolean;
  /**
   * Denotes whether material assets and categories should be included
   * in the tree and search. Default is true.
   */
  includeMaterials?: boolean;
  /**
   * Denotes whether location based assets and categories should be
   * included in the tree and search. Default is true.
   */
  includeLocationBased?: boolean;
  /**
   * Optional callback to be called on asset click
   */
  onAssetClick?: (asset: SearchAsset | InventoryAssetDetailType) => void;
  /**
   * If defined only assets with the given schema will be rendered.
   */
  schemaId?: string;
  /**
   * If defined only assets with the given schema ids will be rendered.
   */
  schemaIds?: string[];
  /**
   * Set of selected asset ids. If truthy, multi asset selection will be
   * enabled.
   */
  selectedAssetIds?: Set<string>;
  /**
   * Setter for selectedAssetIds. Passed to allow lists selecting children
   */
  setSelectedAssetIds?: (ids: Set<string>) => void;
  /**
   * Optional callback to call when asset selection is toggled. Will
   * only be called when asset multi selection is enabled by
   * providing selectedAssetIds.
   */
  onAssetToggle?: (asset: SearchAsset | InventoryAssetDetailType) => void;
  /**
   * Set of asset ids to disable toggling
   */
  disabledAssetIds?: Set<string>;
  /**
   * Optional callback called when a category is clicked.
   */
  onCategoryClick?: (category: InventoryCategoryTree) => void;
  /**
   * Set of selected category ids. If truthy, multi category selection
   * will be enabled.
   */
  selectedCategoryIds?: Set<string>;
  /**
   * Optional callback to call when category selection is toggled. Will
   * only be called when category multi selection is enabled by
   * providing selectedCategoryIds.
   */
  onCategoryToggle?: (category: InventoryCategoryTree) => void;
  /**
   * Set of category ids to disable toggling
   */
  disabledCategoryIds?: Set<string>;
  /**
   * boolean to show root category checkbox
   */
  showRootCategoryCheckbox?: boolean;
  /**
   * denotes which type of search (if any) to include
   */
  search?: 'assets' | 'categories';
  /**
   * If true all descendants of selectedCategoryIds will also be
   * marked as selected and disabled.
   */
  autoSelectChildCategories?: boolean;
  /**
   * Optional prop specifying a different name to use for the root tree.
   * This is useful for instances like "Select All".
   * Defaults to "Inventory".
   */
  rootTreeName?: string;
  /**
   * Optional callback to be called when selecting/deselecting all assets.
   */
  onSelectAllAssetsToggle?: () => void;
  /**
   * Optional boolean value denoting all assets are selected
   */
  allAssetsSelected?: boolean;
  /**
   * Denotes whether to enable the favorite's functionality.
   */
  favoritesEnabled?: boolean;
  /**
   * Denotes whether to include inactive assets or not.
   * Default is true.
   */
  includeInactive?: boolean;
}

const tabs: Tab[] = [
  {
    icon: 'star',
    label: 'favorites',
    value: View.FAVORITES,
    showCount: true,
  },
  {
    label: 'all equipment and material',
    value: View.MATERIALS,
  },
];

export const isAsset = (tree: Tree): tree is InventoryAssetDetailType => {
  // @ts-ignore
  return !R.isNil(tree.attributes);
};

const InventoryAssetTree = ({
  rootCategory,
  onAssetClick,
  schemaId,
  schemaIds = [],
  selectedAssetIds,
  setSelectedAssetIds,
  onAssetToggle,
  disabledAssetIds,
  onCategoryClick,
  selectedCategoryIds,
  onCategoryToggle,
  disabledCategoryIds = new Set([]),
  showRootCategoryCheckbox = false,
  autoSelectChildCategories,
  search,
  onSelectAllAssetsToggle,
  allAssetsSelected,
  includeAssets = true,
  includeMaterials = true,
  includeLocationBased = true,
  rootTreeName = 'Inventory',
  favoritesEnabled = false,
  includeInactive = true,
  onlyPublishedCategories = null,
}: Props) => {
  const [open, setOpen] = useState<Set<string>>(
    new Set([rootCategory?.id || 'inventory']),
  );
  const [query, setQuery] = useState<string>('');
  const defaultView = favoritesEnabled ? View.FAVORITES : View.MATERIALS;
  const [activeView, setActiveView] = useState<View>(defaultView);

  const userProfile = useUserProfile();
  const [favoriteMaterials, setFavoriteMaterials] = useState<any>([]);

  const [uniqueFavoritesCount, setUniqueFavoritesCount] = useState<number>();
  const [favoritesLists, setFavoritesLists] = useState<FavoritesList[]>([]);

  const canAddFavorite = uniqueFavoritesCount < FAVORITES_LIMIT;
  const canAddList = favoritesLists.length < FAVORITES_LIST_LIMIT;

  const [
    getFavoritesListTree,
    { loading: favoritesLoading, error: favoritesError },
  ] = useLazyQuery<
    { favoritesListTree: FavoritesListTreeConnection },
    { input: FavoritesListsTreeConnectionInput }
  >(GET_FAVORITES_LIST_TREE, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const favoritesListsResponse = R.pathOr(
        [],
        ['favoritesListTree', 'favoritesLists'],
        data,
      );

      const favoritesListsData = favoritesListsResponse.map(list => ({
        ...list,
        favorites: list.favorites.map(flattenFavoriteData),
      }));
      const allFavorites = R.uniq(
        favoritesListsData.reduce((acc, currentList) => {
          return isNilOrEmpty(currentList.favorites)
            ? acc
            : [...acc, ...currentList.favorites];
        }, []),
      );
      setUniqueFavoritesCount(data.favoritesListTree.totalUniqueFavorites);
      setFavoritesLists(favoritesListsData);
      setFavoriteMaterials(allFavorites);
    },
  });

  const handleGetFavorites = async () => {
    await getFavoritesListTree({
      variables: {
        input: {
          userId: userProfile.userId,
          subjectType: FavoriteSubjectType.MATERIAL,
        },
      },
    });
  };

  useEffect(() => {
    if (favoritesEnabled) {
      handleGetFavorites();
    }
  }, []);

  const [createList] = useMutation<{ input: FavoritesListCreateInput }>(
    CREATE_FAVORITES_LIST,
    {
      onCompleted: () => handleGetFavorites(),
    },
  );

  const [createFavorite] = useMutation<{ input: FavoriteCreateInput }>(
    CREATE_FAVORITE,
    {
      onCompleted: () => handleGetFavorites(),
    },
  );

  const [updateFavorite] = useMutation<{ input: FavoriteUpdateInput }>(
    UPDATE_FAVORITE,
    {
      onCompleted: () => handleGetFavorites(),
    },
  );

  const handleCreateFavorite = async item => {
    try {
      await createFavorite({
        variables: {
          input: {
            userId: userProfile.userId,
            subjectId: item.id,
            subjectType: FavoriteSubjectType.MATERIAL,
            subjectName: item.name,
            listIds: item.listIds,
          },
        },
      });
    } catch (err) {
      const status = err?.networkError?.statusCode;
      let message = 'Failed to add favorite. Please try again.';
      if (status === 409) {
        message = 'You have already favorited this item.';
      }
      Snackbar.error({ message });
    }
  };

  const handleUpdateFavorite = async (favorite: FavoriteDetail) => {
    try {
      await updateFavorite({
        variables: {
          input: {
            userId: userProfile.userId,
            id: favorite.favoriteId,
            listIds: favorite.listIds || [],
          },
        },
      });
    } catch (err) {
      Snackbar.error({
        message: 'Failed to update favorite. Please try again.',
      });
    }
  };

  const handleAddList = async listName => {
    try {
      await createList({
        variables: {
          input: {
            userId: userProfile.userId,
            subjectType: FavoriteSubjectType.MATERIAL,
            name: listName,
          },
        },
      });
    } catch (err) {
      const status = err?.networkError?.statusCode;
      let message = 'Failed to add list. Please try again.';
      if (status === 409) {
        message = 'A list with that name already exists';
      }
      Snackbar.error({ message });
    }
  };

  const assetSearchEnabled = search === 'assets' && includeAssets;
  const categorySearchEnabled = search === 'categories';
  const level = assetSearchEnabled || categorySearchEnabled ? 0 : 1;

  const {
    fetchMore,
    getData,
    hasMore,
    isLoading,
    hasError,
  } = useGroupedPagination<InventoryAssetDetailType>({
    limit: ASSETS_LIMIT,
    fetcher: (id: string, page: number, limit: number) => {
      return api.get<InventoryAssetDetailType[]>(INVENTORY_ASSETS_ENDPOINT, {
        page,
        limit,
        parentCategoryId: id,
        includeInactive,
      });
    },
  });

  const showSearchResults = query.length >= 3;

  const [categoryTree, { loading: loadingTree }] = useInventoryCategoryTree({
    params: {
      schemaId,
      schemaIds: schemaIds.join(','),
      includeAssetInfo: true,
      locationBased: !includeMaterials,
      materials: !includeLocationBased,
      ...(!R.isNil(onlyPublishedCategories)
        ? { onlyPublished: onlyPublishedCategories }
        : {}),
    },
  });

  const rootTree = useMemo(() => {
    if (!categoryTree) {
      return null;
    }

    if (!rootCategory) {
      return { ...categoryTree, name: rootTreeName };
    }

    const category = getCategoryById(rootCategory.id, [categoryTree]);

    if (category?.hasAssets) {
      fetchMore(category.id);
    }

    const names = [
      ...getCategoryPathNames(categoryTree, rootCategory.categoryPath),
      rootCategory.name,
    ];

    return { ...category, name: names.join(' / ') };
  }, [rootCategory, categoryTree]);

  const handleToggle = useCallback(
    (tree: Tree) => {
      const { id } = tree;
      if (!disabledCategoryIds.has(tree.id)) {
        const hasAssets = !isAsset(tree) && tree.hasAssets;

        // first time category with assets has been opened
        if (
          includeAssets &&
          !open.has(id) &&
          hasAssets &&
          !getData(id).length
        ) {
          fetchMore(id);
        }

        if (!isAsset(tree) && onCategoryClick) {
          onCategoryClick(tree);
        }

        setOpen(toggleFromSet(open, id));
      }
    },
    [fetchMore, getData, includeAssets, onCategoryClick, setOpen],
  );

  const handleAssetClick = useCallback(
    (asset: InventoryAssetDetailType | SearchAsset) => {
      if (onAssetClick) {
        onAssetClick(asset);
      }
    },
    [onAssetClick],
  );

  const handleCategoryClick = useCallback(
    (category: InventoryCategoryTree) => {
      if (onCategoryClick && !disabledCategoryIds.has(category.id)) {
        onCategoryClick(category);
      }
    },
    [onCategoryClick],
  );

  const handleCategoryToggle = useCallback(
    (event: React.MouseEvent<any> | null, category: InventoryCategoryTree) => {
      event?.stopPropagation();

      if (onCategoryToggle) {
        onCategoryToggle(category);
      }

      if (category.id === rootTree.id && onSelectAllAssetsToggle) {
        onSelectAllAssetsToggle();
      }
    },
    [onCategoryToggle, onSelectAllAssetsToggle, rootTree],
  );

  const handleSearchCategoryToggle = useCallback(
    (category: InventoryCategoryTree) => {
      handleCategoryToggle(null, category);
    },
    [handleCategoryToggle],
  );

  const handleAssetToggle = useCallback(
    (
      event: React.MouseEvent<any>,
      asset: SearchAsset | InventoryAssetDetailType,
    ) => {
      event.stopPropagation();

      if (onAssetToggle) {
        onAssetToggle(asset);
      }
    },
    [onAssetToggle],
  );

  const treeView = (
    <TreeView level={level} loadingTree={loadingTree} trees={[rootTree]} />
  );

  const favoritesView = favoritesEnabled ? (
    <FavoritesListsTab
      favoritesLists={favoritesLists}
      subjectType={FavoriteSubjectType.MATERIAL}
      selectedFavoriteIds={selectedAssetIds}
      disabledFavoriteIds={disabledAssetIds}
      handleFavoriteToggle={handleAssetToggle}
      setSelectedFavoriteIds={setSelectedAssetIds}
      updateFavorite={handleUpdateFavorite}
      allFavorites={favoriteMaterials}
      handleAddList={handleAddList}
      canAddFavorite={canAddFavorite}
      canAddList={canAddList}
      favoritesError={favoritesError}
      favoritesLoading={favoritesLoading}
      handleGetFavorites={handleGetFavorites}
    />
  ) : null;

  const views = {
    [View.FAVORITES]: favoritesView,
    [View.MATERIALS]: treeView,
  };

  const contextValue = {
    allAssetsSelected,
    autoSelectChildCategories,
    updateFavorite: handleUpdateFavorite,
    createFavorite: handleCreateFavorite,
    disabledAssetIds,
    disabledCategoryIds,
    favoritesEnabled,
    favoriteMaterials,
    fetchMore,
    getData,
    handleAssetClick,
    handleAssetToggle,
    handleCategoryToggle,
    handleToggle,
    hasError,
    hasMore,
    includeAssets,
    isLoading,
    onSelectAllAssetsToggle,
    open,
    rootTree,
    schemaId,
    selectedAssetIds,
    selectedCategoryIds,
    showRootCategoryCheckbox,
    favoritesLists,
    handleAddList,
    canAddFavorite,
    canAddList,
  };

  return (
    <TreeContext.Provider value={contextValue}>
      <div styleName="container">
        <SearchInput
          assetSearchEnabled={assetSearchEnabled}
          categorySearchEnabled={categorySearchEnabled}
          query={query}
          setQuery={setQuery}
        />
        {showSearchResults && (
          <>
            {assetSearchEnabled ? (
              <InventoryAssetSearch
                includeLocationBasedAssets={includeLocationBased}
                includeMaterialAssets={includeMaterials}
                includeInactive={includeInactive}
                onAssetToggle={onAssetToggle}
                parentCategoryId={rootCategory?.id}
                query={query}
                schemaIds={schemaIds}
              />
            ) : (
              <InventoryCategorySearch
                includeLocationBasedCategories={includeLocationBased}
                includeMaterialCategories={includeMaterials}
                onCategoryClick={handleCategoryClick}
                onCategoryToggle={handleSearchCategoryToggle}
                query={query}
                tree={categoryTree}
              />
            )}
          </>
        )}
        {!showSearchResults && (
          <>
            {!favoritesEnabled && treeView}
            {favoritesEnabled && (
              <div styleName="favorites-tree-view-container">
                <TabContainer
                  activeView={activeView}
                  setActiveView={setActiveView}
                  count={uniqueFavoritesCount}
                  tabs={tabs.filter(
                    (tab: Tab) =>
                      !(!favoritesEnabled && tab.value === View.FAVORITES),
                  )}
                  views={views}
                />
              </div>
            )}
          </>
        )}
      </div>
    </TreeContext.Provider>
  );
};

export default InventoryAssetTree;
