import React from 'react';

import { RadioGroup } from '@atom/mui';

const { RadioButton } = RadioGroup;

enum BooleanOption {
  YES = 'YES',
  NO = 'NO',
}

interface Props {
  id: string;
  value: any;
  onChange: (value: any) => void;
  disabled: boolean;
}

const BooleanInput = ({ id, value, onChange, disabled }: Props) => {
  const handleChange = event => {
    onChange(event.target.value === BooleanOption.YES);
  };

  const currentValue = value ? BooleanOption.YES : BooleanOption.NO;

  return (
    <RadioGroup key={id} value={currentValue} onChange={handleChange}>
      <RadioButton value={BooleanOption.YES} label="Yes" disabled={disabled} />
      <RadioButton value={BooleanOption.NO} label="No" disabled={disabled} />
    </RadioGroup>
  );
};

export default BooleanInput;
