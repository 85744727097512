// Created for use in budgets page remaining columns
// - displays value as currency
// - displays green percent bar if value is within limit
// - displays red percent bar if value is negative, using absolute value
// - caps bar at 100% if value exceeds limit

import React, { useMemo } from 'react';

import colors from '@atom/styles/colors';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';

interface Props {
  value: number;
  limit: number;
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%',
  },
  barContainer: {
    height: '0.5rem',
    borderRadius: '0.25rem',
    width: '100%',
  },
  bar: {
    height: '0.5rem',
    borderRadius: '0.25rem',
  },
};

const PercentBar = ({ value, limit }: Props) => {
  const width = useMemo(() => {
    const absVal = Math.abs(value);
    return absVal > limit ? '100%' : `${(absVal / limit) * 100}%`;
  }, [value, limit]);

  const isRed = useMemo(() => value < 0, [value]);

  const backgroundColor = useMemo(() => {
    return isRed ? colors.budgetGraphs.redBk : colors.budgetGraphs.greenBk;
  }, [isRed]);

  const barColor = useMemo(() => {
    return isRed ? colors.budgetGraphs.red : colors.budgetGraphs.green;
  }, [isRed]);

  return (
    <div style={styles.container}>
      <span>{numberToLocaleString(value)}</span>
      <div style={{ ...styles.barContainer, background: backgroundColor }}>
        <div style={{ ...styles.bar, width, background: barColor }} />
      </div>
    </div>
  );
};

export default PercentBar;
