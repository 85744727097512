import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation } from '@apollo/client';
import debounce from 'lodash.debounce';

import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import { ATTRIBUTE_UPDATE } from '@atom/graph/schema';
import {
  AttributeDataType,
  AttributeUpdateInput,
  SchemaTree,
} from '@atom/types/schema';

import { updatePendingCreation } from '../subItemDetail/subItemDetailUtilities';

import BooleanInput from './defaultValueInputs/BooleanInput';
import DateInput from './defaultValueInputs/DateInput';
import EnumMultipleInput from './defaultValueInputs/EnumMultipleInput';
import EnumSingleInput from './defaultValueInputs/EnumSingleInput';
import NumberInput from './defaultValueInputs/NumberInput';
import TextInput from './defaultValueInputs/TextInput';
import { DEBOUNCED_DATA_TYPES } from './attributeDetailUtilities';

import './attributeDetail.css';

const DEBOUNCE_TIME = 500;

const DefaultValueSection = () => {
  const {
    schemaTree,
    selectedAttribute,
    setSelectedAttribute,
    selectedSubItem,
    selectedAttributeRoute,
    refetchSchemaTree,
    setPendingCreations,
  } = useContext(SchemaDetailContext);

  const [defaultValue, setDefaultValue] = useState<any>(
    selectedAttribute.defaultValue,
  );

  useEffect(() => {
    setDefaultValue(selectedAttribute.defaultValue);
  }, [selectedAttribute.id]);

  const [updateAttribute] = useMutation<
    { attributeUpdate: SchemaTree },
    { input: AttributeUpdateInput }
  >(ATTRIBUTE_UPDATE);

  // If attribute is a temp attribute, values must be updated in pendingCreations state
  const handlePendingCreationUpdate = (value: string) => {
    const updatedAttribute = { ...selectedAttribute, defaultValue: value };

    setPendingCreations(prev =>
      updatePendingCreation(
        selectedSubItem.id,
        selectedAttributeRoute,
        prev,
        updatedAttribute,
      ),
    );

    setSelectedAttribute(updatedAttribute);
  };

  const handleAttributeUpdate = async (value: any) => {
    if (selectedAttribute.isTempAttribute) {
      handlePendingCreationUpdate(value);
    } else {
      setSelectedAttribute(prev => ({ ...prev, defaultValue: value }));

      await updateAttribute({
        variables: {
          input: {
            schemaId: selectedSubItem.id,
            attributeGroupId: selectedAttributeRoute.attributeGroupId,
            attributeId: selectedAttribute.id,
            defaultValue: value,
          },
        },
      });

      refetchSchemaTree();
    }
  };

  const updateAttributeDebounced = useCallback(
    debounce((newDefaultValue: any) => {
      handleAttributeUpdate(newDefaultValue);
    }, DEBOUNCE_TIME),
    [selectedAttribute],
  );

  const onChange = async (newDefaultValue: any) => {
    setDefaultValue(newDefaultValue);

    if (DEBOUNCED_DATA_TYPES.has(selectedAttribute.dataType)) {
      if (!newDefaultValue) {
        updateAttributeDebounced.cancel();
      } else {
        updateAttributeDebounced(newDefaultValue);
      }
    } else {
      await handleAttributeUpdate(newDefaultValue);
    }
  };

  const content = useMemo(() => {
    const props = {
      id: selectedAttribute.id,
      value: defaultValue,
      options: selectedAttribute.enumeration,
      onChange,
      disabled: schemaTree.isPublished && !selectedAttribute.isTempAttribute,
    };

    switch (selectedAttribute.dataType) {
      case AttributeDataType.SHORT_TEXT:
      case AttributeDataType.LONG_TEXT: {
        return <TextInput {...props} />;
      }
      case AttributeDataType.BOOLEAN: {
        return <BooleanInput {...props} />;
      }
      case AttributeDataType.DATE: {
        return <DateInput {...props} />;
      }
      case AttributeDataType.ENUM_SINGLE: {
        return <EnumSingleInput {...props} />;
      }
      case AttributeDataType.ENUM_MULTIPLE: {
        return <EnumMultipleInput {...props} />;
      }
      case AttributeDataType.NUMBER:
      case AttributeDataType.CURRENCY: {
        return <NumberInput {...props} />;
      }
      default: {
        return <div />;
      }
    }
  }, [selectedAttribute, defaultValue, onChange]);

  return (
    <div styleName="default-value-container">
      <div styleName="default-value-title">Default Value</div>
      <div>{content}</div>
    </div>
  );
};

export default DefaultValueSection;
