// Based on approach in https://webperf.tips/tip/react-hook-paint/

// Instead of performance.mark in the above example, this uses
//   a trigger param to execute the callback param on the
//   following animation frame paint.

import { useEffect } from 'react';

interface Props {
  /**
   * when set to true, triggers the frame detection
   */
  trigger: boolean;
  /**
   * function to run on next frame
   */
  callback: () => void;
}

const runAfterFramePaint = callback => {
  requestAnimationFrame(() => {
    const messageChannel = new MessageChannel();
    messageChannel.port1.onmessage = callback;
    messageChannel.port2.postMessage(undefined);
  });
};

const useDetectFramePaint = ({ trigger, callback }: Props) => {
  useEffect(() => {
    if (!trigger) {
      return;
    }
    runAfterFramePaint(callback);
  }, [trigger, callback]);
};

export default useDetectFramePaint;
