import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import * as R from 'ramda';

import {
  GET_BUDGET,
  GET_BUDGET_SUMMARIES,
  GET_BUDGET_UNIT,
} from '@atom/graph/budget';
import { usePreferences } from '@atom/hooks/usePreferences';
import { useScrollPos } from '@atom/hooks/useScrollPos';
import { Checkbox, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  BasicBudget,
  BasicBudgetUnit,
  Budget,
  BudgetCategory,
  BudgetItem,
  BudgetItemTemplate,
  BudgetSummary,
  BudgetSummaryConnection,
  BudgetSummaryConnectionInput,
  BudgetUnit,
  BudgetUnitConnectionInput,
} from '@atom/types/budget';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailBreadcrumbs from './BudgetDetailBreadcrumbs';
import BudgetDetailCategoryFilter from './BudgetDetailCategoryFilter';
import BudgetDetailComparisonFilter from './BudgetDetailComparisonFilter';
import BudgetDetailContext from './BudgetDetailContext';
import BudgetDetailHeader from './BudgetDetailHeader';
import BudgetDetailItemFilter from './BudgetDetailItemFilter';
import BudgetDetailUnit from './BudgetDetailUnit';
import { EditField } from './budgetDetailUtils';

import './budgetDetail.css';

interface Props {
  match: any;
}

const styles = {
  filterContainer: {
    padding: '0.5rem 0',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },

  filterLabel: {
    color: colors.neutral.gray,
  },

  separator: {
    height: '2rem',
    width: '1px',
    backgroundColor: colors.neutral.ash,
    display: 'inline-block',
    padding: 0,
    marginLeft: '0.25rem',
  },
};

const getBorder = (scrolled: boolean) => ({
  borderBottom: scrolled ? `1px solid ${colors.neutral.ash}` : 'none',
});

const BudgetDetail = ({ match }: Props) => {
  const preferences = usePreferences();
  const showExpenditures = R.pathOr(
    false,
    ['budgeting', 'showExpenditures'],
    preferences,
  );

  const [parentBudgetUnit, setParentBudgetUnit] = useState<BudgetUnit>();
  const [childBudgetUnits, setChildBudgetUnits] = useState<BudgetUnit[]>([]);
  const [budgetCategories, setBudgetCategories] = useState<BudgetCategory[]>(
    null,
  );
  const [unitsVisited, setUnitsVisited] = useState<{ id?: BasicBudgetUnit }>(
    {},
  );

  // filters
  const [showTracking, setShowTracking] = useState<boolean>(showExpenditures);
  const [excludeZeroBudgetItems, setExcludeZeroBudgetItems] = useState<boolean>(
    false,
  );
  const [categoryFilters, setCategoryFilters] = useState<BudgetCategory[]>([]);
  const [budgetItemTemplateFilters, setBudgetItemTemplateFilters] = useState<
    BudgetItemTemplate[]
  >([]);
  const categoryIds = useMemo(
    () => categoryFilters.map(category => category?.id),
    [categoryFilters],
  );
  const budgetItemTemplateNames = useMemo(
    () => budgetItemTemplateFilters.map(template => template.name),
    [budgetItemTemplateFilters],
  );

  // Budget Comparisons
  const [openComparisonRowId, setOpenComparisonRowId] = useState<string>();
  const [comparisonBudgets, setComparisonBudgets] = useState<BasicBudget[]>([]);
  const [budgetSummaries, setBudgetSummaries] = useState<BudgetSummary[]>([]);
  const [budgetOverviewSummaries, setBudgetOverviewSummaries] = useState<
    BudgetSummary[]
  >([]);

  const [fetchSummaries, { loading: loadingSummaries }] = useLazyQuery<
    { budgetSummary: BudgetSummaryConnection },
    { input: BudgetSummaryConnectionInput }
  >(GET_BUDGET_SUMMARIES, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const summaries: BudgetSummary[] = R.pathOr(
        [],
        ['budgetSummary', 'budgetSummary'],
        data,
      );
      setBudgetSummaries(summaries);
    },
  });

  // Item Editing
  const [editingItem, setEditingItem] = useState<BudgetItem>();
  const [editingField, setEditingField] = useState<EditField>();
  const [itemHoverId, setItemHoverId] = useState<string>();
  const [expandedCategories, setExpandedCategories] = useState<Set<string>>(
    new Set([]),
  );

  // Top-level call to get budget for this page
  const { data: budgetData, loading } = useQuery(GET_BUDGET, {
    variables: {
      id: match.params?.id,
    },
    fetchPolicy: 'network-only',
  });
  const budget: Budget = R.pathOr(null, ['budget'], budgetData);

  const scrolled = useScrollPos() > 0;

  // Clear Comparison Selections on budget navigation
  useEffect(() => {
    setComparisonBudgets([]);
    setParentBudgetUnit(null);
    setChildBudgetUnits([]);
    setBudgetCategories([]);
  }, [budget]);

  // Update unitsVisited and clear overview summaries on parent unit navigation
  useEffect(() => {
    setBudgetOverviewSummaries([]);
    if (
      !isNilOrEmpty(parentBudgetUnit) &&
      !unitsVisited[parentBudgetUnit?.id]
    ) {
      setUnitsVisited({
        ...unitsVisited,
        [parentBudgetUnit?.id]: parentBudgetUnit,
      });
    }
  }, [parentBudgetUnit]);

  const [
    getParentUnit,
    { data: parentBudgetUnitData, loading: loadingParentUnit },
  ] = useLazyQuery<
    { budgetUnit: BudgetUnit },
    { input: BudgetUnitConnectionInput }
  >(GET_BUDGET_UNIT, {
    fetchPolicy: 'network-only',
  });

  useEffect(
    () =>
      setParentBudgetUnit(R.pathOr(null, ['budgetUnit'], parentBudgetUnitData)),
    [parentBudgetUnitData],
  );

  useEffect(() => {
    if (!isNilOrEmpty(budget)) {
      getParentUnit({
        variables: {
          input: {
            budgetId: budget?.id,
            budgetUnitId: budget?.rootBudgetUnitId,
            categoryIds,
            budgetItemTemplateNames,
          },
        },
      });
    }
  }, [budget]);

  return (
    <BudgetDetailContext.Provider
      value={{
        budget,
        parentBudgetUnit,
        setParentBudgetUnit,
        childBudgetUnits,
        setChildBudgetUnits,
        budgetCategories,
        setBudgetCategories,
        unitsVisited,
        setUnitsVisited,
        categoryFilters,
        categoryIds,
        setCategoryFilters,
        budgetItemTemplateFilters,
        setBudgetItemTemplateFilters,
        budgetItemTemplateNames,
        editingItem,
        setEditingItem,
        editingField,
        setEditingField,
        expandedCategories,
        setExpandedCategories,
        excludeZeroBudgetItems,
        setExcludeZeroBudgetItems,
        getParentUnit,
        loadingParentUnit,
        comparisonBudgets,
        setComparisonBudgets,
        budgetSummaries,
        setBudgetSummaries,
        budgetOverviewSummaries,
        setBudgetOverviewSummaries,
        openComparisonRowId,
        setOpenComparisonRowId,
        fetchSummaries,
        loadingSummaries,
        itemHoverId,
        setItemHoverId,
        showTracking,
      }}
    >
      <>
        {loading || isNilOrEmpty(budget) ? (
          <Progress />
        ) : (
          <>
            <BudgetDetailHeader />
            <div styleName="container">
              <div
                styleName="fixed-filters-container"
                style={getBorder(scrolled)}
              >
                <BudgetDetailBreadcrumbs />
                <div style={styles.filterContainer}>
                  <div style={styles.filterLabel}>Filters:</div>
                  <BudgetDetailCategoryFilter />
                  <BudgetDetailItemFilter />
                  <div style={styles.separator} />
                  <div style={styles.filterLabel}>Compare:</div>
                  <BudgetDetailComparisonFilter />
                  {showExpenditures && (
                    <>
                      <div style={styles.separator} />
                      <Checkbox
                        onChange={() => setShowTracking(prev => !prev)}
                        checked={showTracking}
                        label="Show Tracking"
                      />
                    </>
                  )}
                </div>
              </div>
              <BudgetDetailUnit />
            </div>
          </>
        )}
      </>
    </BudgetDetailContext.Provider>
  );
};

export default BudgetDetail;
