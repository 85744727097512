import React, { useContext, useMemo, useState } from 'react';
import * as R from 'ramda';

import RenameModal from '@atom/components/common/RenameModal';
// @ts-ignore
import addItemIcon from '@atom/components/common/svgIcons/add_subItem.svg';
import TextOverflowTooltip from '@atom/components/common/tooltip/TextOverflowTooltip';
import WorkOrderAssetViewContext from '@atom/components/common/workOrderDetail/workOrderAssetView/WorkOrderAssetViewContext';
import { Icon, IconButton, Menu, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';
import { AttributeGroupsItem, ChangeType } from '@atom/types/inventory';
import { WorkOrderAssetTreeElement } from '@atom/types/work';
import api from '@atom/utilities/api';
import { assetTreeHasDownstreamChanges } from '@atom/utilities/assetUtilities';
import attributeDisplayUtility from '@atom/utilities/attributeDisplayUtility';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { WORK_ORDERS_ENDPOINT } from '@atom/utilities/endpoints';
import iconUtilities from '@atom/utilities/iconUtilities';

import AddElementModal from './addElementModal/AddElementModal';
import ElementControls from './ElementControls';

import './workOrderAssetTree.css';

const { MenuItem } = Menu;

const PADDING_OFFSET = 2;

const styles = {
  blankStyle: {
    boxSizing: 'border-box',
    padding: '24px',
  },
};

interface Props {
  element: WorkOrderAssetTreeElement;
  indentation: number;
}

type OpenModalType = 'ADD_ELEMENT' | 'EDIT' | 'DELETE' | null;
type OpenModal = { type: OpenModalType; id: string | null };

const getRowNameStyle = (hasPendingChangesOrParentAdded: boolean) => {
  let styleName = 'row-name';

  if (hasPendingChangesOrParentAdded) {
    styleName += ' blue';
  }

  return styleName;
};

const getRowStyle = (
  element: WorkOrderAssetTreeElement,
  activeElementId: string,
) => {
  return element.id === activeElementId ? 'asset-row selected' : 'asset-row';
};

const ElementRow = ({ element, indentation }: Props) => {
  const {
    workOrderDetail,
    workOrderAsset,
    refetchWorkOrderAssetTree,
    setActiveElementId,
    activeElementId,
    previewView,
  } = useContext(WorkOrderAssetViewContext);

  const [expanded, setExpanded] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<OpenModal>({
    type: null,
    id: null,
  });

  const hasPendingChanges = useMemo(() => {
    return (
      !!element.changeType &&
      element.changeType !== ChangeType.ELEMENTS_CHANGED &&
      element.changeType !== ChangeType.PARENT_ADDED
    );
  }, [element.changeType]);

  const hasParentAdded = useMemo(() => {
    return element.changeType === ChangeType.PARENT_ADDED;
  }, [element.changeType]);

  const isDeleted = useMemo(() => {
    return element?.changeType === ChangeType.DELETED;
  }, [element]);

  const hasDownstreamChanges = useMemo(() => {
    return assetTreeHasDownstreamChanges(element);
  }, [element]);

  const showControls =
    hasRolePermissions(ROLE_SETS.MANAGER) && hasPendingChanges && !previewView;

  const showAdditionalOptions =
    hasRolePermissions(ROLE_SETS.INSPECTOR) && !workOrderDetail.isClosed;

  const arrowDirection = expanded ? 'arrow_drop_down' : 'arrow_right';

  const elementIcon = iconUtilities.getDataManagementElementIcon(
    element.markerId,
    hasPendingChanges || hasParentAdded,
  );

  const leftIcon = (
    <>
      {!R.isEmpty(element.elements) ? (
        <IconButton onClick={() => setExpanded(!expanded)}>
          <Icon>{arrowDirection}</Icon>
        </IconButton>
      ) : (
        <div style={styles.blankStyle} />
      )}
      <div styleName="icon-container">
        {elementIcon}
        {hasDownstreamChanges && <div styleName="change-indicator icon" />}
      </div>
    </>
  );

  const containerStyle = {
    paddingLeft: `calc(1rem + ${PADDING_OFFSET * indentation}rem)`,
  };

  const renameElement = async (id: string, name: string) => {
    const endpoint = `${WORK_ORDERS_ENDPOINT}/${workOrderDetail.id}/assets/${id}`;
    await api.patch(endpoint, { name });

    refetchWorkOrderAssetTree();
  };

  const deleteElement = async () => {
    const endpoint = `${WORK_ORDERS_ENDPOINT}/${workOrderDetail.id}/assets/${element.id}`;
    await api.delete(endpoint);

    if (element.id === activeElementId) {
      setActiveElementId(workOrderAsset.id);
    }

    refetchWorkOrderAssetTree();
  };

  const getAttributeSubText = (): string => {
    if (
      R.isEmpty(element.attributeGroups) ||
      R.isNil(element.attributeGroups)
    ) {
      return '';
    }

    const attributes = element.attributeGroups.reduce(
      (acc: string[], group: AttributeGroupsItem): string[] => {
        return [...acc, ...group.attributes];
      },
      [],
    );

    return attributes.reduce((acc: string, attributeId: string): string => {
      const attribute = element.attributes[attributeId];
      if (!attribute.isVisibleAsSubtext) {
        return acc;
      }

      const label = attribute.name;
      const value = attributeDisplayUtility.display(
        attribute.value,
        attribute.dataType,
        attribute.unit,
      );

      const text = `${label}: ${value}`;
      return acc ? `${acc}, ${text}` : text;
    }, '');
  };

  return (
    <>
      <div
        styleName={getRowStyle(element, activeElementId)}
        style={containerStyle}
        onClick={() => setActiveElementId(element.id)}
      >
        <div styleName="asset-name-container">
          {leftIcon}
          <div styleName="row-name-container">
            <div
              styleName={getRowNameStyle(hasPendingChanges || hasParentAdded)}
            >
              <span styleName={`row-name-text ${isDeleted ? 'deleted' : ''}`}>
                {element.name}
              </span>
            </div>
            <div styleName="row-name subtext">
              <TextOverflowTooltip
                text={getAttributeSubText()}
                width="100%"
                lightTooltip
              />
            </div>
          </div>
        </div>
        <div styleName="controls-container">
          {showControls && <ElementControls element={element} />}
          {showAdditionalOptions && (
            <>
              <Menu>
                {element.changeType !== ChangeType.ADDED && (
                  <MenuItem
                    startAdornment={
                      <Icon>
                        <img src={addItemIcon} />
                      </Icon>
                    }
                    onClick={() =>
                      setOpenModal({ type: 'ADD_ELEMENT', id: element.id })
                    }
                  >
                    Add Sub Items
                  </MenuItem>
                )}
                <MenuItem
                  startAdornment={<Icon>edit</Icon>}
                  onClick={() => setOpenModal({ type: 'EDIT', id: element.id })}
                >
                  Edit
                </MenuItem>
                {element.isNew && element.changeType !== ChangeType.DELETED && (
                  <MenuItem
                    startAdornment={<Icon>delete</Icon>}
                    onClick={() =>
                      setOpenModal({ type: 'DELETE', id: element.id })
                    }
                  >
                    Delete
                  </MenuItem>
                )}
              </Menu>
              {element.changeType !== ChangeType.ADDED && (
                <AddElementModal
                  open={
                    openModal.type === 'ADD_ELEMENT' &&
                    openModal.id === element.id
                  }
                  closeModal={() => setOpenModal({ type: null, id: null })}
                  element={element}
                />
              )}
              <RenameModal
                open={openModal.type === 'EDIT' && openModal.id === element.id}
                closeModal={() => setOpenModal({ type: null, id: null })}
                id={element.id}
                name={element.name}
                type="element"
                renameAction={renameElement}
              />
              {element.isNew && element.changeType !== ChangeType.DELETED && (
                <Modal
                  open={
                    openModal.type === 'DELETE' && openModal.id === element.id
                  }
                  title="Delete Element"
                  onCancel={() => setOpenModal({ type: null, id: null })}
                  confirmButtonText="Delete"
                  ConfirmButtonProps={{
                    style: { background: colors.brand.red },
                  }}
                  onConfirm={() => {
                    deleteElement();
                    setOpenModal({ type: null, id: null });
                  }}
                >
                  Are you sure you want to delete this element?
                </Modal>
              )}
            </>
          )}
        </div>
      </div>
      {expanded ? (
        element.elements.map((elem: WorkOrderAssetTreeElement) => {
          return (
            <ElementRow
              key={elem.id}
              element={elem}
              indentation={indentation + 1}
            />
          );
        })
      ) : (
        <div />
      )}
    </>
  );
};

export default ElementRow;
