import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import InventoryAssetTree from '@atom/components/common/inventoryAssetTree/InventoryAssetTree';
// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import { CREATE_ASSET } from '@atom/graph/asset';
import { useCurrentLocation } from '@atom/hooks/useCurrentLocation';
import { useSchemas } from '@atom/hooks/useSchemas';
import {
  Button,
  Icon,
  IconButton,
  Modal,
  Select,
  Switch,
  TextField,
} from '@atom/mui';
import { budgetUnitsSelector } from '@atom/selectors/preferencesSelectors';
import colors from '@atom/styles/colors';
import {
  AssetCreateInput,
  AssetDetail,
  InventoryCategoryTree,
} from '@atom/types/inventory';
import {
  SchemaBudgetType,
  StartEndReadingOption,
  StockBasedOption,
} from '@atom/types/schema';
import { getColorFromColorId } from '@atom/utilities/colorUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './createAssetModal.css';

const { MenuItem } = Select;

const styles = {
  modal: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 2rem',
  },
  backButton: {
    marginRight: '1rem',
  },
  icon: {
    marginRight: '1.5rem',
  },
  unitOption: {
    textTransform: 'uppercase',
  },
};

export interface Props {
  open: boolean;
  onClose: () => void;
}

export enum View {
  INVENTORY,
  CATEGORIES,
}

const modalStyles: { [key in View]: React.CSSProperties } = {
  [View.INVENTORY]: styles.modal,
  [View.CATEGORIES]: { ...styles.modal, height: '50vh' },
};

const CreateAssetModal = ({ open, onClose }: Props) => {
  const budgetUnits = useSelector(budgetUnitsSelector);

  const { schemas = [] } = useSchemas();
  const { pointLocation, lineStringLocation } = useCurrentLocation();

  const [view, setView] = useState<View>(View.INVENTORY);
  const [name, setName] = useState<string>('');
  const [schemaId, setSchemaId] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<
    InventoryCategoryTree
  >();
  const [rate, setRate] = useState<number>();
  const [unit, setUnit] = useState<string>('');
  const [isStockBased, setIsStockBased] = useState<boolean>(false);
  const [isStartEndReading, setIsStartEndReading] = useState<boolean>(false);
  const [isStartEndCalculated, setIsStartEndCalculated] = useState<boolean>(
    false,
  );
  const [quantityOnHand, setQuantityOnHand] = useState<number>();

  const [createAsset] = useMutation<
    { assetCreate: AssetDetail },
    { input: AssetCreateInput }
  >(CREATE_ASSET);

  const resetState = () => {
    setView(View.INVENTORY);
    setName('');
    setSchemaId('');
    setSelectedCategory(null);
    setIsStockBased(false);
    setIsStartEndReading(false);
    setIsStartEndCalculated(false);
    setQuantityOnHand(null);
    setRate(null);
    setUnit('');
    onClose();
  };

  const handleCategorySelect = (category: InventoryCategoryTree) => {
    if (isNilOrEmpty(category?.categories) && category.name !== 'inventory') {
      setSelectedCategory(category);
      setSchemaId(category?.schemaId);
      setView(View.INVENTORY);
    }
  };

  const handleClearCategory = () => {
    setSelectedCategory(null);
    setSchemaId(null);
  };

  const selectedSchema = useMemo(() => {
    return schemas.find(schema => schema.id === schemaId);
  }, [schemaId]);

  useEffect(() => {
    if (selectedSchema?.budget) {
      setRate(selectedSchema?.budget?.rate);
    }
  }, [selectedSchema?.budget?.rate]);

  useEffect(() => {
    if (selectedSchema?.budget) {
      setUnit(selectedSchema?.budget?.unit);
    }
  }, [selectedSchema?.budget?.unit]);

  useEffect(() => {
    if (selectedSchema?.budget) {
      setIsStockBased(
        selectedSchema?.budget?.stockBasedOption ===
          StockBasedOption.STOCK_BASED,
      );
    }
  }, [selectedSchema?.budget?.stockBasedOption]);

  useEffect(() => {
    if (selectedSchema?.budget) {
      setIsStartEndReading(
        selectedSchema?.budget?.startEndReadingOption ===
          StartEndReadingOption.START_END_READING,
      );
    }
  }, [selectedSchema?.budget?.startEndReadingOption]);

  const handleClose = () => {
    resetState();
    onClose();
  };

  const updateRate = event => {
    const newTimeRate = event.target.value
      ? Math.abs(Number(event.target.value))
      : null;

    setRate(newTimeRate);
  };

  const updateQuantityOnHand = event => {
    const newQuantityOnHand = event.target.value
      ? Math.abs(Number(event.target.value))
      : null;

    setQuantityOnHand(newQuantityOnHand);
  };

  const handleStockBasedToggle = () => {
    setIsStockBased(!isStockBased);
    setQuantityOnHand(isStockBased ? null : quantityOnHand);
  };

  const handleStartEndReadingToggle = () => {
    setIsStartEndReading(!isStartEndReading);
  };

  const handleStartEndCalculatedToggle = () => {
    setIsStartEndCalculated(!isStartEndCalculated);
  };

  const handleSubmit = async () => {
    const location =
      selectedSchema?.locationType === 'LineString'
        ? lineStringLocation
        : pointLocation;

    await createAsset({
      variables: {
        input: {
          name,
          externalId: name,
          schemaId: selectedSchema.id,
          categoryId: selectedCategory.id,
          ...(!selectedSchema?.isMaterial && { location }),
          ...(selectedSchema?.isMaterial && {
            budget: {
              rate,
              unit,
              quantityOnHand,
              isStockBased,
              isStartEndReading,
              isStartEndCalculated,
            },
          }),
        },
      },
    });

    handleClose();
  };

  const folderColor = useMemo(() => {
    return !isNilOrEmpty(selectedCategory)
      ? getColorFromColorId(selectedCategory?.colorId)
      : colors.neutral.gray;
  }, [selectedCategory]);

  const isSchemaSelectDisabled = !isNilOrEmpty(selectedCategory?.schemaId);
  const isStockBasedToggleDisabled =
    selectedSchema?.budget?.stockBasedOption === StockBasedOption.STOCK_BASED;
  const isStartEndReadingToggleDisabled =
    selectedSchema?.budget?.startEndReadingOption ===
    StartEndReadingOption.START_END_READING;
  const isRateDisabled =
    selectedSchema?.budget?.type === SchemaBudgetType.FIXED;
  const isUnitDisabled =
    selectedSchema?.budget?.type === SchemaBudgetType.FIXED ||
    selectedSchema?.budget?.type === SchemaBudgetType.VARIABLE_COST;
  const isCreateDisabled =
    !name ||
    !selectedCategory?.id ||
    !schemaId ||
    (selectedSchema?.isMaterial && R.isNil(rate)) ||
    (selectedSchema?.isMaterial && !unit) ||
    (selectedSchema?.isMaterial &&
      selectedSchema?.budget?.stockBasedOption ===
        StockBasedOption.STOCK_BASED &&
      !quantityOnHand);

  const showStockBased =
    selectedSchema?.budget?.stockBasedOption !== StockBasedOption.NONE;

  const showStartEndReading =
    !isStockBased &&
    selectedSchema?.budget?.startEndReadingOption !==
      StartEndReadingOption.NONE;

  const options = ['', ...budgetUnits];

  const getContent = () => {
    switch (view) {
      case View.INVENTORY:
        return (
          <>
            <div styleName="inventory-row">
              <img style={styles.icon} src={renameIcon} />
              <TextField
                label="Name"
                value={name}
                onChange={event => setName(event.target.value)}
              />
            </div>
            <div styleName="inventory-row">
              <Icon style={styles.icon} color={folderColor}>
                folder
              </Icon>
              {selectedCategory ? (
                <div styleName="category-tile">
                  <div>{selectedCategory.name}</div>
                  <IconButton
                    onClick={handleClearCategory}
                    edge="end"
                    size="small"
                  >
                    <Icon>close</Icon>
                  </IconButton>
                </div>
              ) : (
                <div
                  styleName="choose-button"
                  onClick={() => setView(View.CATEGORIES)}
                >
                  Choose Folder
                </div>
              )}
            </div>
            <div styleName="inventory-row">
              <Icon style={styles.icon}>category</Icon>
              <Select
                fullWidth
                label="Inventory Type"
                value={schemaId}
                onChange={event => setSchemaId(event.target.value)}
                disabled={isSchemaSelectDisabled}
              >
                {schemas.map(schema => {
                  return (
                    <MenuItem key={schema.id} value={schema.id}>
                      {schema.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            {selectedSchema?.isMaterial && (
              <>
                <div styleName="inventory-row">
                  <Icon style={styles.icon}>monetization_on</Icon>
                  <div styleName="cost-input-container">
                    <TextField
                      label="Cost"
                      value={rate}
                      type="number"
                      fullWidth
                      onChange={updateRate}
                      InputProps={{
                        startAdornment: '$',
                      }}
                      disabled={isRateDisabled}
                    />
                    <div styleName="separator">per</div>
                    <Select
                      label="Unit"
                      fullWidth
                      InputProps={{
                        style: styles.unitOption,
                      }}
                      onChange={event => setUnit(event.target.value)}
                      value={unit}
                      disabled={isUnitDisabled}
                    >
                      {options.map(option => {
                        return (
                          <MenuItem
                            key={option}
                            value={option || null}
                            style={styles.unitOption}
                          >
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                </div>
                {showStockBased && (
                  <>
                    <div styleName="inventory-row">
                      <Switch
                        checked={isStockBased}
                        onChange={handleStockBasedToggle}
                        label="Stock-based Inventory"
                        disabled={isStockBasedToggleDisabled}
                      />
                    </div>
                    {isStockBased && (
                      <div styleName="inventory-row quantity">
                        <TextField
                          label="Total Available"
                          value={quantityOnHand}
                          type="number"
                          fullWidth
                          onChange={updateQuantityOnHand}
                        />
                        <div styleName="quantity-suffix">{unit}</div>
                      </div>
                    )}
                  </>
                )}
                {showStartEndReading && (
                  <>
                    <div styleName="inventory-row">
                      <Switch
                        checked={isStartEndReading}
                        onChange={handleStartEndReadingToggle}
                        label="Enable Start and End Readings"
                        disabled={isStartEndReadingToggleDisabled}
                      />
                    </div>
                    {isStartEndReading && (
                      <div styleName="inventory-row">
                        <Switch
                          checked={isStartEndCalculated}
                          onChange={handleStartEndCalculatedToggle}
                          label="Used Calculated from Start and End Readings"
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        );
      case View.CATEGORIES:
        return (
          <InventoryAssetTree
            includeAssets={false}
            search="categories"
            onCategoryClick={category => handleCategorySelect(category)}
            disabledCategoryIds={new Set(['inventory'])}
          />
        );
      default:
        return null;
    }
  };

  const getTitle = (): React.ReactNode => {
    if (view === View.CATEGORIES) {
      return (
        <>
          <IconButton
            style={styles.backButton}
            onClick={() => setView(View.INVENTORY)}
          >
            <Icon>arrow_back</Icon>
          </IconButton>
          Choose Folder
        </>
      );
    }

    return 'Create Inventory';
  };

  const getFooter = (): React.ReactNode => {
    switch (view) {
      case View.INVENTORY: {
        return (
          <div>
            <Button onClick={handleClose} style={{ marginRight: '0.5rem' }}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              disabled={isCreateDisabled}
            >
              Create
            </Button>
          </div>
        );
      }
      case View.CATEGORIES: {
        return <div />;
      }
      default: {
        return <div />;
      }
    }
  };

  return (
    <Modal
      title={getTitle()}
      open={open}
      onCancel={onClose}
      onExited={() => resetState()}
      footer={getFooter()}
      disableFooter={view === View.CATEGORIES}
      contentStyle={modalStyles[view] || styles.modal}
    >
      {getContent()}
    </Modal>
  );
};

export default CreateAssetModal;
