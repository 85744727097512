import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import { GET_BUDGET_ITEM_TEMPLATE_FILTERS } from '@atom/graph/budget';
import { ComboSelect } from '@atom/mui';
import {
  BudgetItemTemplate,
  BudgetItemTemplatesFilterConnection,
  BudgetItemTemplatesFilterConnectionInput,
} from '@atom/types/budget';
import { objectIdsMatch } from '@atom/utilities/objectCompareUtilities';

import BudgetDetailContext from './BudgetDetailContext';
import { DEBOUNCE_TIME } from './budgetDetailUtils';

import './budgetDetail.css';

const LIMIT = 25;
const MIN_SEARCH_CHARS = 2;

const BudgetDetailItemFilter = () => {
  const {
    budgetItemTemplateFilters,
    setBudgetItemTemplateFilters,
    budget,
    categoryIds,
    parentBudgetUnit,
    getParentUnit,
  } = useContext(BudgetDetailContext);

  const [itemFiltersCart, setItemFiltersCart] = useState<BudgetItemTemplate[]>(
    budgetItemTemplateFilters,
  );
  const [itemFiltersSnapshot, setItemFiltersSnapshot] = useState<
    BudgetItemTemplate[]
  >(budgetItemTemplateFilters);

  const [searchOptions, setSearchOptions] = useState<BudgetItemTemplate[]>([]);
  const [searchPage, setSearchPage] = useState<number>(1);
  const [searchTotal, setSearchTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [open, setOpen] = useState<boolean>();
  const [options, setOptions] = useState<BudgetItemTemplate[]>([]);
  const [query, setQuery] = useState<string>('');

  const searching = useMemo(() => query.length >= MIN_SEARCH_CHARS, [query]);

  const [fetchOptions, { loading: loadingOptions }] = useLazyQuery<
    { budgetItemTemplatesFilter: BudgetItemTemplatesFilterConnection },
    { input: BudgetItemTemplatesFilterConnectionInput }
  >(GET_BUDGET_ITEM_TEMPLATE_FILTERS, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const nextOptions = R.pathOr(
        [],
        ['budgetItemTemplatesFilter', 'budgetItemTemplates'],
        data,
      );
      const newTotal = data?.budgetItemTemplatesFilter?.totalCount || 0;
      if (searching) {
        setSearchOptions([...searchOptions, ...nextOptions]);
        setSearchTotal(newTotal);
      } else {
        setOptions([...options, ...nextOptions]);
        setTotal(newTotal);
      }
    },
  });

  const handleSelectOption = (option: BudgetItemTemplate) => {
    const selectedIds = itemFiltersCart.map(template => template.id);
    const newTemplates = selectedIds.includes(option.id)
      ? itemFiltersCart.filter(template => template.id !== option.id)
      : [...itemFiltersCart, option];
    setItemFiltersCart(newTemplates);
  };

  const handleClearSelections = () => {
    setBudgetItemTemplateFilters([]);
    setItemFiltersCart([]);
    getParentUnit({
      variables: {
        input: {
          budgetId: budget?.id,
          budgetUnitId: parentBudgetUnit?.id,
          categoryIds,
          budgetItemTemplateNames: [],
        },
      },
    });
  };

  const fetchOptionsDebounced = useCallback(
    debounce((value: string) => {
      fetchOptions({
        variables: {
          input: {
            budgetId: budget.id,
            name: value,
            limit: LIMIT,
          },
        },
      });
    }, DEBOUNCE_TIME),
    [],
  );

  const handleQueryChange = (value: string = '') => {
    setQuery(value);
    setSearchPage(1);
    setSearchOptions([]);
    if (!value) {
      fetchOptionsDebounced.cancel();
    } else if (value.length >= MIN_SEARCH_CHARS) {
      fetchOptionsDebounced(value);
    } else {
      fetchOptionsDebounced.cancel();
    }
  };

  const handleFetchOptions = () => {
    fetchOptions({
      variables: {
        input: {
          budgetId: budget.id,
          limit: LIMIT,
        },
      },
    });
  };

  const handlePageScroll = (nextPage: number) => {
    if (searching) {
      setSearchPage(nextPage);
    } else {
      setPage(nextPage);
    }

    fetchOptions({
      variables: {
        input: {
          budgetId: budget.id,
          limit: LIMIT,
          page: nextPage,
          name: query,
        },
      },
    });
  };

  useEffect(() => {
    // Cache initial selected item snapshot when user opens menu
    if (open === true) {
      setItemFiltersSnapshot(itemFiltersCart);
    }
    if (
      open === false &&
      !objectIdsMatch(itemFiltersSnapshot, itemFiltersCart)
    ) {
      setBudgetItemTemplateFilters(itemFiltersCart);
      getParentUnit({
        variables: {
          input: {
            budgetId: budget?.id,
            budgetUnitId: parentBudgetUnit?.id,
            categoryIds,
            budgetItemTemplateNames: itemFiltersCart.map(item => item?.id),
          },
        },
      });
    }
  }, [open]);

  return (
    <ComboSelect
      open={open}
      setOpen={setOpen}
      itemName="Budget Item"
      loading={loadingOptions}
      options={searching ? searchOptions : options}
      total={searching ? searchTotal : total}
      page={searching ? searchPage : page}
      handlePageScroll={handlePageScroll}
      fetchOptions={handleFetchOptions}
      query={query}
      onQueryChange={handleQueryChange}
      selectedOptions={itemFiltersCart}
      clearSelections={() => handleClearSelections()}
      onSelectOption={handleSelectOption}
      dataCyLabel="BudgetItemFilter"
    />
  );
};

export default BudgetDetailItemFilter;
