import React from 'react';

import { TextField } from '@atom/mui';

interface Props {
  id: string;
  value: any;
  onChange: (value: any) => void;
  disabled: boolean;
}

const TextInput = ({ id, value, onChange, disabled }: Props) => {
  return (
    <TextField
      key={id}
      value={value || ''}
      id="text"
      onChange={event => onChange(event?.target?.value)}
      variant="standard"
      multiline
      disabled={disabled}
    />
  );
};

export default TextInput;
