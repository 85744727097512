import * as React from 'react';

import { Icon, Menu, Modal, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import { UserProfile } from '@atom/types/user';
import { FILES_ENDPOINT } from '@atom/utilities/endpoints';
import { getMediaIcon } from '@atom/utilities/iconUtilities';
import textDisplayUtilities from '@atom/utilities/textDisplayUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';

import Checkbox from '../../common/Checkbox';
import RenameModal from '../../common/RenameModal';

import '../../inventoryDetail/bodyPanes/folders/folders.css';

const { MenuItem } = Menu;

interface Props {
  media?: any[];
  userProfile: UserProfile;
  renameMedia: (id: string, name: string) => any;
  deleteMedia: (id: string) => any;
  loading?: boolean;
  toggleCheck: (id: string, checked: boolean) => any;
  selectedItems: any[];
  canUpdateMedia: boolean;
  canDeleteMedia: boolean;
}

type ModalType = 'EDIT' | 'DELETE' | null;

const tableDisplayHeaders = ['', 'name', 'uploaded by', 'uploaded on', ''];
const tableFields = [
  'checkbox',
  'name',
  'createdBy',
  'createdDate',
  'additionalOptions',
];

class ElementMediaTable extends React.Component<Props> {
  state: {
    openModal: ModalType;
    currentRowId: any;
  } = { openModal: null, currentRowId: null };

  toggleModal = (modalToOpen: ModalType, id: any) => {
    this.setState({ openModal: modalToOpen, currentRowId: id });
  };

  buildTableHeaders = displayHeaders => {
    return displayHeaders.map((header, headerIndex) => {
      return <th key={headerIndex}>{header}</th>;
    });
  };

  buildRowFields = (row, fields) => {
    return fields.map((field, fieldIndex) => {
      return this.buildCell(row, field, fieldIndex);
    });
  };

  buildRows = (dataRows, fields) => {
    return dataRows.map((row, rowIndex) => {
      return <tr key={rowIndex}>{this.buildRowFields(row, fields)}</tr>;
    });
  };

  buildCell = (row, field, index) => {
    const {
      userProfile,
      renameMedia,
      deleteMedia,
      toggleCheck,
      selectedItems,
      canUpdateMedia,
      canDeleteMedia,
    } = this.props;

    const fieldTemplates = {
      checkbox: (
        <td key={index} styleName="checkbox">
          <Checkbox
            isChecked={selectedItems.includes(row.id)}
            toggleCheck={() =>
              toggleCheck(row.id, selectedItems.includes(row.id))
            }
          />
        </td>
      ),
      name: (
        <td key={index} styleName="clickable">
          <div styleName="name-row-container">
            <div styleName="name-row-icon">
              {getMediaIcon(row.fileExtension, row.fileId, row.name)}
            </div>
            <a
              styleName="clickable-link"
              href={`${FILES_ENDPOINT}/${row.fileId}`}
              target="_blank"
            >{`${row.name}.${row.fileExtension}`}</a>
          </div>
        </td>
      ),
      createdBy: (
        <td key={index}>
          {row.createdBy.firstName
            ? `${row.createdBy.firstName} ${row.createdBy.lastName}`
            : `${userProfile.firstName} ${userProfile.lastName}`}
        </td>
      ),
      createdDate: <td key={index}>{setDisplayDate(row.createdDate)}</td>,
      additionalOptions: (
        <td key={index} styleName="additional-options">
          <Menu>
            <MenuItem startAdornment={<Icon>file_download</Icon>}>
              Download
              <a
                styleName="download-link"
                href={`${FILES_ENDPOINT}/${row.fileId}`}
                download={`${row.name}.${row.fileExtension}`}
              />
            </MenuItem>
            {canUpdateMedia && (
              <MenuItem
                startAdornment={<Icon>edit</Icon>}
                onClick={() => this.toggleModal('EDIT', row.id)}
              >
                Edit
              </MenuItem>
            )}
            {canDeleteMedia && (
              <MenuItem
                startAdornment={<Icon>delete</Icon>}
                onClick={() => this.toggleModal('DELETE', row.id)}
              >
                Delete
              </MenuItem>
            )}
          </Menu>
          {canDeleteMedia && (
            <RenameModal
              open={
                this.state.openModal === 'EDIT' &&
                this.state.currentRowId === row.id
              }
              closeModal={() => this.toggleModal(null, null)}
              id={row.id}
              name={row.name}
              type={row.type}
              renameAction={renameMedia}
            />
          )}
          {canDeleteMedia && (
            <Modal
              open={
                this.state.openModal === 'DELETE' &&
                this.state.currentRowId === row.id
              }
              title={`Delete ${textDisplayUtilities.capitalize(row.type)}s`}
              onCancel={() => this.toggleModal(null, null)}
              confirmButtonText="Delete"
              ConfirmButtonProps={{ style: { background: colors.brand.red } }}
              onConfirm={() => {
                deleteMedia(row.id);
                this.toggleModal(null, null);
              }}
            >
              {`Are you sure you want to delete this ${row.type}?`}
            </Modal>
          )}
        </td>
      ),
    };
    return fieldTemplates[field] || <td key={index}>{row[field]}</td>;
  };

  render() {
    const { media, loading } = this.props;
    return (
      <div styleName="main-pane detail">
        <table styleName="sticky">
          <thead>
            <tr>{this.buildTableHeaders(tableDisplayHeaders)}</tr>
          </thead>
          <tbody styleName="hidden">{this.buildRows(media, tableFields)}</tbody>
        </table>
        {!loading ? (
          <table>
            <thead styleName="hidden">
              <tr>{this.buildTableHeaders(tableDisplayHeaders)}</tr>
            </thead>
            <tbody>{this.buildRows(media, tableFields)}</tbody>
          </table>
        ) : (
          <Progress />
        )}
      </div>
    );
  }
}

export default ElementMediaTable;
