import React from 'react';

import { AttributesType } from '@atom/types/inventory';
import { SchemaTreeAttribute } from '@atom/types/schema';

import AttributeRow from './AttributeRow';

import './attributeDetails.css';

interface Props {
  attributes: AttributesType[] | SchemaTreeAttribute[];
  attributeGroupName?: string;
  onPendingApproval?: (
    action: string,
    attributeGroupName: string,
    attribute: AttributesType,
  ) => void;
  canManageChanges?: boolean;
  schemaPreview?: boolean;
}

const AttributesList = ({
  attributes,
  attributeGroupName,
  onPendingApproval,
  canManageChanges,
  schemaPreview,
}: Props) => {
  return (
    <div styleName="attribute-list-container">
      {attributes.map(attribute => (
        <AttributeRow
          key={attribute.id}
          attribute={attribute}
          attributeGroupName={attributeGroupName}
          onPendingApproval={onPendingApproval}
          canManageChanges={canManageChanges}
          schemaPreview={schemaPreview}
        />
      ))}
    </div>
  );
};

export default AttributesList;
