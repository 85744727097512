import React, { useContext } from 'react';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { BudgetModal } from '@atom/types/budget';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import {
  Client,
  isCurrentClient,
} from '@atom/utilities/featureToggleUtilities';

import BudgetsContext from './BudgetsContext';

import '../../styles/detail-header.css';

const BudgetsHeader = () => {
  const { setActiveModal } = useContext(BudgetsContext);

  // TODO: Replace temporary permissions here with RBAC
  // - ALDOT users need ADMIN permission
  // - UDOT users need ORG_ADMIN permission
  const roleNeeded: string[] = isCurrentClient([Client.ALDOT])
    ? ROLE_SETS.ADMIN
    : ROLE_SETS.ORG_ADMIN;
  const canAddBudgets: boolean = hasRolePermissions(roleNeeded);

  return (
    <div styleName="header-container budgets-header">
      <div styleName="name-container">
        <span>Budgets</span>
      </div>
      {canAddBudgets && (
        <IconButton
          tooltip="Add Budget"
          onClick={() => setActiveModal(BudgetModal.CREATE)}
        >
          <Icon color={colors.neutral.white}>add</Icon>
        </IconButton>
      )}
    </div>
  );
};

export default BudgetsHeader;
