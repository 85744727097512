import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';

import {
  DELETE_BUDGET,
  GET_BUDGET_OPTIONS,
  GET_BUDGET_TEMPLATES,
  UPDATE_BUDGET,
} from '@atom/graph/budget';
import { Icon, IconButton, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  Budget,
  BudgetModal,
  BudgetsConnection,
  BudgetsConnectionInput,
  BudgetTemplatesConnection,
  BudgetUpdateInput,
} from '@atom/types/budget';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import {
  Client,
  isCurrentClient,
} from '@atom/utilities/featureToggleUtilities';
import history from '@atom/utilities/history';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetsFormModal from '../budgets/budgetsModal/BudgetsFormModal';
import DeleteModal from '../common/DeleteModal';

import BudgetDetailBudgetNav from './BudgetDetailBudgetNav';
import BudgetDetailContext from './BudgetDetailContext';
import { getDateRange } from './budgetDetailUtils';

import '../../styles/detail-header.css';

const BUDGETS_LIMIT = 25;

const BudgetDetailHeader = () => {
  const { budget } = useContext(BudgetDetailContext);
  const [activeModal, setActiveModal] = useState<BudgetModal>(null);
  const [options, setOptions] = useState<Budget[]>(null);
  const [total, setTotal] = useState<number>(null);
  const [page, setPage] = useState<number>(1);

  // TODO: Replace temporary permissions here with RBAC
  // - ALDOT users need ADMIN permission
  // - UDOT users need ORG_ADMIN permission
  const roleNeeded: string[] = isCurrentClient([Client.ALDOT])
    ? ROLE_SETS.ADMIN
    : ROLE_SETS.ORG_ADMIN;

  const canEditBudgets: boolean = hasRolePermissions(roleNeeded);
  const canDeleteBudgets: boolean = hasRolePermissions(roleNeeded);

  const [
    getBudgets,
    { data: budgetOptionsData, loading: loadingBudgets },
  ] = useLazyQuery<
    { budgets: BudgetsConnection },
    { input: BudgetsConnectionInput }
  >(GET_BUDGET_OPTIONS, { fetchPolicy: 'network-only' });

  useEffect(
    () =>
      getBudgets({
        variables: {
          input: {
            templateId: budget.templateId,
            page: 1,
            limit: BUDGETS_LIMIT,
            sortBy: 'name,asc',
          },
        },
      }),
    [],
  );

  useEffect(() => {
    if (!isNilOrEmpty(budgetOptionsData)) {
      const nextOptions: Budget[] = R.pathOr(
        [],
        ['budgetOptions', 'budgets'],
        budgetOptionsData,
      );
      const newTotal: number = R.pathOr(
        0,
        ['budgetOptions', 'totalCount'],
        budgetOptionsData,
      );
      setOptions([...(options || []), ...nextOptions]);
      setTotal(newTotal);
    }
  }, [budgetOptionsData]);

  const handlePageScroll = (nextPage: number) => {
    setPage(nextPage);
    getBudgets({
      variables: {
        input: {
          templateId: budget.templateId,
          page: nextPage,
          limit: BUDGETS_LIMIT,
          sortBy: 'name,asc',
        },
      },
    });
  };

  const budgetNamesUsed = useMemo(
    () => new Set(options?.map(({ name }) => name) || []),
    [options],
  );

  const { data: templatesData, loading: loadingTemplates } = useQuery<{
    budgetTemplates: BudgetTemplatesConnection;
  }>(GET_BUDGET_TEMPLATES);

  const templates = R.pathOr(
    [],
    ['budgetTemplates', 'budgetTemplates'],
    templatesData,
  );

  const [deleteBudget] = useMutation<{}, { id: string }>(DELETE_BUDGET);

  const handleDeleteBudget = async () => {
    try {
      await deleteBudget({ variables: { id: budget.id } });
      Snackbar.info({
        message: `Deleted budget "${budget.name}"`,
      });
      history.push('/budgets');
    } catch {
      Snackbar.error({
        message: `An error occurred while deleting ${budget.name}. Please Try Again.`,
      });
    } finally {
      setActiveModal(null);
    }
  };

  const [updateBudget, { loading: loadingUpdate }] = useMutation<
    { budgetUpdate: Budget },
    { input: BudgetUpdateInput }
  >(UPDATE_BUDGET);

  const handleUpdateBudget = async (input: BudgetUpdateInput) => {
    try {
      await updateBudget({
        variables: {
          input,
        },
      });
      Snackbar.info({
        message: `Updated budget "${input.name}"`,
      });
    } catch (error) {
      Snackbar.error({
        message:
          'An error occurred while updating your budget. Please Try Again.',
      });
    } finally {
      setActiveModal(null);
    }
  };

  const title: string = !R.isEmpty(budget)
    ? `${budget.name}${getDateRange(budget)}`
    : '';

  return (
    <div styleName="header-container budgets-header">
      <div styleName="name-container budgets-name-container">
        <IconButton onClick={() => history.push('/budgets')} tooltip="Go back">
          <Icon color={colors.neutral.white}>arrow_back</Icon>
        </IconButton>
        <span>{title}</span>
        <BudgetDetailBudgetNav
          options={options}
          total={total}
          loading={loadingBudgets}
          page={page}
          handlePageScroll={handlePageScroll}
        />
      </div>
      <div>
        {canEditBudgets && (
          <IconButton
            onClick={() => setActiveModal(BudgetModal.EDIT)}
            tooltip="Edit Budget"
          >
            <Icon color={colors.neutral.white}>edit</Icon>
          </IconButton>
        )}
        {canDeleteBudgets && (
          <IconButton
            onClick={() => setActiveModal(BudgetModal.DELETE)}
            tooltip="Delete Budget"
          >
            <Icon color={colors.neutral.white}>delete</Icon>
          </IconButton>
        )}
        {activeModal === BudgetModal.EDIT && !loadingTemplates && (
          <BudgetsFormModal
            onClose={() => setActiveModal(null)}
            budget={budget}
            modalType={activeModal}
            templates={templates}
            budgetNamesUsed={budgetNamesUsed}
            handleUpdateBudget={handleUpdateBudget}
            saving={loadingUpdate}
          />
        )}
        <DeleteModal
          open={activeModal === BudgetModal.DELETE}
          title="Delete Budget?"
          content={`Are you sure you want to delete budget "${budget?.name}"?`}
          onCancel={() => setActiveModal(null)}
          onConfirm={handleDeleteBudget}
        />
      </div>
    </div>
  );
};

export default BudgetDetailHeader;
