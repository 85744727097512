import React, { useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import * as R from 'ramda';

import CreateAssetModal from '@atom/components/common/createAssetModal/CreateAssetModal';
import CreateAssetModalNew from '@atom/components/common/createAssetModal_NEW/CreateAssetModal';
import CreateWorkModal from '@atom/components/common/createWorkModals/CreateWorkModal';
// @ts-ignore
import createAssetIcon from '@atom/components/common/svgIcons/createAsset.svg';
//  @ts-ignore
import createWorkIcon from '@atom/components/common/svgIcons/createWork.svg';
// @ts-ignore
import createIcon from '@atom/components/common/svgIcons/globalCreate.svg';
import { usePreferences } from '@atom/hooks/usePreferences';
import { IconButton, Popover, Tooltip } from '@atom/mui';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
// TODO: Remove UDOT specific code and move to RBAC
// TODO: [AM-15154] Remove feature toggle for required attributes
import {
  Environment,
  isCurrentEnvironment,
  isCurrentTenant,
  Tenant,
} from '@atom/utilities/featureToggleUtilities';

import './navigation.css';

const styles = {
  iconStyle: {
    height: '30px',
    marginRight: '1rem',
  },
};

const useStyles = makeStyles({
  paper: {
    marginTop: '5px',
    width: '224px',
  },
});

const CreateControls = () => {
  const anchor = useRef();
  const classes = useStyles();
  const preferences = usePreferences();
  const requireWorkType = R.pathOr(
    false,
    ['workOrders', 'requireWorkType'],
    preferences,
  );

  const [open, setOpen] = useState<boolean>(false);
  const [assetModalOpen, setAssetModalOpen] = useState<boolean>(false);
  const [workModalOpen, setWorkModalOpen] = useState<boolean>(false);

  const canCreateAssets = isCurrentTenant([Tenant.UDOT])
    ? hasRolePermissions(ROLE_SETS.INSPECTOR)
    : hasRolePermissions(ROLE_SETS.MANAGER);

  const tooltip = canCreateAssets ? 'Create' : 'Create Work';

  const handleClick = () => {
    return canCreateAssets ? setOpen(true) : setWorkModalOpen(true);
  };

  const handleAssetClick = () => {
    setOpen(false);
    setAssetModalOpen(true);
  };

  const handleWorkClick = () => {
    setOpen(false);
    setWorkModalOpen(true);
  };

  return (
    hasRolePermissions(ROLE_SETS.INSPECTOR) && (
      <>
        <div styleName="additional-buttons-wrapper" ref={anchor}>
          <div styleName="additional-buttons-container create">
            <span styleName="additional-buttons-left-divider" />
            <IconButton onClick={handleClick} data-cy="globalCreate">
              <Tooltip placement="bottom-end" title={tooltip}>
                <img src={createIcon} />
              </Tooltip>
            </IconButton>
          </div>
        </div>
        <Popover
          open={open}
          anchorEl={anchor.current}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          onClose={() => setOpen(false)}
          classes={classes}
        >
          <div styleName="menu-row" onClick={handleAssetClick}>
            <img style={styles.iconStyle} src={createAssetIcon} />
            <div>Create Inventory</div>
          </div>
          <div
            styleName="menu-row"
            onClick={handleWorkClick}
            data-cy="globalCreateWork"
          >
            <img style={styles.iconStyle} src={createWorkIcon} />
            <div>Create Work</div>
          </div>
        </Popover>
        {canCreateAssets &&
          (isCurrentEnvironment([Environment.DEV]) ? (
            <CreateAssetModalNew
              open={assetModalOpen}
              onClose={() => setAssetModalOpen(false)}
            />
          ) : (
            <CreateAssetModal
              open={assetModalOpen}
              onClose={() => setAssetModalOpen(false)}
            />
          ))}
        <CreateWorkModal
          redirectOnCreate
          open={workModalOpen}
          workTemplateRequired={requireWorkType}
          onClose={() => setWorkModalOpen(false)}
        />
      </>
    )
  );
};

export default CreateControls;
