import React, { useContext } from 'react';

import { Button, Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import { BudgetModal } from '@atom/types/budget';

import BudgetsContext from './BudgetsContext';

import './budgets.css';

const styles = {
  blueButton: {
    backgroundColor: colors.brand.blue,
    color: colors.neutral.white,
    padding: '0.5rem 1rem',
  },
  text: {
    fontSize: '1rem',
  },
  icon: {
    fontSize: '3rem',
    color: colors.neutral.silver,
  },
};

const BudgetsNoneAvailable = () => {
  const { setActiveModal } = useContext(BudgetsContext);
  return (
    <div styleName="container-no-budgets">
      <Icon style={styles.icon}>insert_chart_outlined</Icon>
      <span style={styles.text}>No Budget Found</span>
      <Button
        style={styles.blueButton}
        onClick={() => setActiveModal(BudgetModal.CREATE)}
        startIcon={<Icon color={colors.neutral.white}>add</Icon>}
        data-cy="dashboardEmptyCustomizeIcon"
      >
        CREATE A BUDGET
      </Button>
    </div>
  );
};

export default BudgetsNoneAvailable;
