/* eslint-disable */
const colors = {
  brand: {
    red: '#ED3620',
    blue: '#0283EA',
    turquoise: '#00ACC1',
    teal: '#26A69A',
    orange: '#FF8F00',
    green: '#3FA142',
    purple: '#9209AE',
    violet: '#3E4EB8',
    navy: '#0048C7',
  },
  neutral: {
    white: '#FFF',
    mist: '#FAFAFA',
    fog: '#F5F5F5',
    typhoon: '#EEE',
    ash: '#DDD',
    silver: '#CCC',
    gray: '#999',
    dim: '#555',
    dark: '#333',
    black: '#000',
  },
  opacity: {
    black0_8: 'rgba(0, 0, 0, 0.8)',
    black0_6: 'rgba(0, 0, 0, 0.6)',
    black0_54: 'rgba(0, 0, 0, 0.56)',
    black0_4: 'rgba(0, 0, 0, 0.4)',
    black0_2: 'rgba(0, 0, 0, 0.2)',
    black0_1: 'rgba(0, 0, 0, 0.1)',
    black0_0: 'rgba(0, 0, 0, 0)',
    white0_8: 'rgba(255, 255, 255, 0.8)',
    white0_6: 'rgba(255, 255, 255, 0.6)',
    white0_4: 'rgba(255, 255, 255, 0.4)',
    white0_2: 'rgba(255, 255, 255, 0.2)',
  },
  utility: {
    highlight: 'rgba(0,0,0,0.05)',
    activeBlue: '#007DEF',
    blueHighlight: '#F2F9FE',
    lightBlueHighlight: 'rgba(0, 125, 239, 0.08)',
    link: '#0283EA',
    positive: '#3FA142',
    negative: '#ED3620',
    boxShadow: 'rgba(0,0,0,0.3)',
    disabled: '#999',
    error: '#CC4B3C',
    muiError: '#D32F2F',
  },
  status: {
    requested: '#8F66F7',
    unassigned: '#FF5722',
    assigned: '#FFA726',
    inprogress: '#1565C0',
    completed: '#4CAF50',
    closed: '#999',
    inReview: '#1BBCD7',
  },
  calendarStatus: {
    requested: '#D6BEFF',
    unassigned: '#FEB7A1',
    assigned: '#FEDBA1',
    inprogress: '#9BC2EA',
    completed: '#A9E1B3',
    closed: '#D7D7D7',
    inReview: '#8DDDEB',
  },
  priority: {
    none: '#999',
    low: '#24A42E',
    medium: '#E9A303',
    high: '#D25D5D',
  },
  ci: {
    excellent: '#018563',
    good: '#8DC94D',
    fair: '#F1B028',
    poor: '#D25D5D',
  },
  timeEntryStatus: {
    draft: '#CCC',
    pending: '#FFA726',
    approved: '#4CAF50',
    rejected: '#ED3620',
  },
  budgetGraphs: {
    red: '#ed3620',
    redBk: '#f8afa6',
    green: '#00cf68',
    greenBk: '#eee',
  },
};

export default colors;
