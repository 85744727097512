import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { makeStyles } from '@mui/styles';

import WorkOrderEnumMultipleField from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderEnumMultipleField';
import WorkOrderEnumSingleField from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderEnumSingleField';
import WorkOrdersContext, {
  WorkOrdersInputActionTypes,
} from '@atom/components/workOrders/WorkOrdersContext';
import { GET_WORK_ORDER_TEMPLATE } from '@atom/graph/workTemplate';
import colors from '@atom/styles/colors';
import {
  FieldFilter,
  WorkOrderField,
  WorkOrderFieldDataType,
} from '@atom/types/work';
import {
  convertDateToLocalDayEnd,
  convertDateToLocalDayStart,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import { isCascadingField } from '@atom/utilities/workOrderFieldUtilities';

import WorkOrdersFiltersDatePicker from './WorkOrdersFiltersDatePicker';
import WorkOrdersFiltersNumericRange from './WorkOrdersFiltersNumericRange';

import './workOrdersFilters.css';

const useClasses = makeStyles({
  label: {
    position: 'unset',
    color: colors.neutral.dim,
  },
});

const WorkOrdersWorkFieldsFilter = () => {
  const classes = useClasses();

  const { workOrdersInputCart, dispatch } = useContext(WorkOrdersContext);

  const [fields, setFields] = useState<WorkOrderField[]>([]);
  const [getWorkOrderTemplate, { data: workOrderTemplateData }] = useLazyQuery<
    { workOrderTemplate: { fields } },
    { id: string }
  >(GET_WORK_ORDER_TEMPLATE);

  useEffect(() => {
    if (workOrderTemplateData) {
      setFields(workOrderTemplateData.workOrderTemplate.fields);
    }
  }, [workOrderTemplateData]);

  useEffect(() => {
    if (workOrdersInputCart.workTemplateIds.length === 1) {
      getWorkOrderTemplate({
        variables: {
          id: workOrdersInputCart.workTemplateIds.join(''),
        },
      });
    } else if (workOrdersInputCart.workTemplateIds.length !== 1) {
      setFields(null);
    }
  }, [workOrdersInputCart.workTemplateIds]);

  const handleDateStartChange = (
    id?: string,
    fieldProperty?: keyof FieldFilter,
  ) => (_, val: any) => {
    const dateMillis = val ? convertDateToLocalDayStart(val) : null;

    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_FIELD,
      data: {
        fieldId: id,
        fieldProperty,
        value: dateMillis,
      },
    });
  };

  const handleDateEndChange = (
    id?: string,
    fieldProperty?: keyof FieldFilter,
  ) => (_, val: any) => {
    const dateMillis = val ? convertDateToLocalDayEnd(val) : null;

    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_FIELD,
      data: {
        fieldId: id,
        fieldProperty,
        value: dateMillis,
      },
    });
  };

  const handleNumericFieldChange = (
    id?: string,
    fieldProperty?: keyof FieldFilter,
  ) => (_, val: any) => {
    const value = val === '' ? null : Number(val);

    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_FIELD,
      data: {
        fieldId: id,
        fieldProperty,
        value,
      },
    });
  };

  const handleChange = (id?: string, fieldProperty?: keyof FieldFilter) => (
    _,
    val: any,
  ) => {
    const value = val ? val.valueOf() : null;

    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_FIELD,
      data: {
        fieldId: id,
        fieldProperty,
        value,
      },
    });
  };

  const getDateValue = (id?: string, fieldProperty?: keyof FieldFilter) => {
    const foundField: any = workOrdersInputCart.fields.find(
      field => field.id === id,
    );

    if (foundField && !isNilOrEmpty(foundField[fieldProperty])) {
      return new Date(Number(foundField[fieldProperty]));
    }

    return null;
  };

  const getField = (id?: string) => {
    const foundField: any = workOrdersInputCart.fields.find(
      field => field.id === id,
    );

    return foundField || null;
  };

  const getContent = (field: WorkOrderField) => {
    if (isCascadingField(field)) {
      return <div />;
    }

    const components = {
      [WorkOrderFieldDataType.DATE]: (
        <div styleName="custom-field-container">
          <WorkOrdersFiltersDatePicker
            field={field}
            startDateValue={getDateValue(field.id, 'valueStart')}
            endDateValue={getDateValue(field.id, 'valueEnd')}
            onStartDateChange={handleDateStartChange(field.id, 'valueStart')}
            onEndDateChange={handleDateEndChange(field.id, 'valueEnd')}
          />
        </div>
      ),
      [WorkOrderFieldDataType.DATE_TIME]: (
        <div styleName="custom-field-container">
          <WorkOrdersFiltersDatePicker
            field={field}
            startDateValue={getDateValue(field.id, 'valueStart')}
            endDateValue={getDateValue(field.id, 'valueEnd')}
            onStartDateChange={handleChange(field.id, 'valueStart')}
            onEndDateChange={handleChange(field.id, 'valueEnd')}
          />
        </div>
      ),
      [WorkOrderFieldDataType.ENUM_SINGLE]: (
        <div styleName="custom-field-container">
          <WorkOrderEnumSingleField
            field={{ ...field, value: getField(field.id)?.value }}
            onChange={handleChange(field.id, 'value')}
            boldLabel
          />
        </div>
      ),
      [WorkOrderFieldDataType.ENUM_MULTIPLE]: (
        <div styleName="custom-field-container">
          <WorkOrderEnumMultipleField
            field={{ ...field, value: getField(field.id)?.values }}
            onChange={handleChange(field.id, 'values')}
            InputLabelProps={{ classes: { root: classes.label } }}
          />
        </div>
      ),
      [WorkOrderFieldDataType.NUMBER]: (
        <div styleName="custom-field-container">
          <WorkOrdersFiltersNumericRange
            field={field}
            startValue={getField(field.id)?.valueStart}
            endValue={getField(field.id)?.valueEnd}
            onStartChange={handleNumericFieldChange(field.id, 'valueStart')}
            onEndChange={handleNumericFieldChange(field.id, 'valueEnd')}
          />
        </div>
      ),
    };

    return components[field.dataType];
  };

  return <>{fields.map(field => getContent(field))}</>;
};

export default WorkOrdersWorkFieldsFilter;
