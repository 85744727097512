import React, { useContext, useMemo, useState } from 'react';
import * as R from 'ramda';

import RenameModal from '@atom/components/common/RenameModal';
// @ts-ignore
import addItemIcon from '@atom/components/common/svgIcons/add_subItem.svg';
import TextOverflowTooltip from '@atom/components/common/tooltip/TextOverflowTooltip';
import WorkOrderAssetViewContext from '@atom/components/common/workOrderDetail/workOrderAssetView/WorkOrderAssetViewContext';
import { Icon, IconButton, Menu } from '@atom/mui';
import { ChangeType, ElementGroupsTreeItem } from '@atom/types/inventory';
import { WorkOrderAssetTreeElement } from '@atom/types/work';
import api from '@atom/utilities/api';
import { assetTreeHasDownstreamChanges } from '@atom/utilities/assetUtilities';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { WORK_ORDERS_ENDPOINT } from '@atom/utilities/endpoints';
import iconUtilities from '@atom/utilities/iconUtilities';
import markerUtilities from '@atom/utilities/markerUtilities';

import AddElementModal from './addElementModal/AddElementModal';
import ElementControls from './ElementControls';
import ElementGroupRow from './ElementGroupRow';
import ElementRow from './ElementRow';

import './workOrderAssetTree.css';

const { MenuItem } = Menu;

const styles = {
  elementIconStyle: {
    height: '30px',
    width: '30px',
  },
};

const getRowNameStyle = (hasChanges: boolean, isNew: boolean) => {
  let styleName = 'row-name';

  if (hasChanges || isNew) {
    styleName += ' blue';
  }

  return styleName;
};

const getRowStyle = (
  element: WorkOrderAssetTreeElement,
  activeElementId: string,
) => {
  return element.id === activeElementId ? 'asset-row selected' : 'asset-row';
};

type OpenModalType = 'ADD_ELEMENT' | 'EDIT' | null;
type OpenModal = { type: OpenModalType; id: string | null };

const WorkOrderAssetTree = () => {
  const {
    workOrderAsset,
    workOrderDetail,
    refetchWorkOrderAssetTree,
    setActiveElementId,
    activeElementId,
    previewView,
  } = useContext(WorkOrderAssetViewContext);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<OpenModal>({
    type: null,
    id: null,
  });

  const hasPendingChanges = useMemo(() => {
    return (
      !!workOrderAsset.changeType &&
      workOrderAsset.changeType !== ChangeType.ELEMENTS_CHANGED &&
      workOrderAsset.changeType !== ChangeType.PARENT_ADDED
    );
  }, [workOrderAsset]);

  const hasDownstreamChanges = useMemo(() => {
    return assetTreeHasDownstreamChanges(workOrderAsset);
  }, [workOrderAsset]);

  const arrowDirection = expanded ? 'arrow_drop_down' : 'arrow_right';

  const renameElement = async (id: string, name: string) => {
    const endpoint = `${WORK_ORDERS_ENDPOINT}/${workOrderDetail.id}/assets/${id}`;
    await api.patch(endpoint, { name });

    refetchWorkOrderAssetTree();
  };

  const renderExpandedArrow =
    !R.isNil(workOrderAsset.elements) && !R.isEmpty(workOrderAsset.elements);

  const showControls =
    hasRolePermissions(ROLE_SETS.MANAGER) && hasPendingChanges && !previewView;

  const showAdditionalOptions =
    hasRolePermissions(ROLE_SETS.INSPECTOR) && !workOrderDetail.isClosed;

  const isRootAsset = workOrderDetail.inventoryAssetId === workOrderAsset.id;

  return (
    <>
      <div
        // @ts-ignore
        styleName={getRowStyle(workOrderAsset, activeElementId)}
        onClick={() => setActiveElementId(workOrderAsset.id)}
      >
        <div styleName="asset-name-container">
          {renderExpandedArrow && (
            <IconButton onClick={() => setExpanded(!expanded)}>
              <Icon>{arrowDirection}</Icon>
            </IconButton>
          )}
          <div styleName="icon-container">
            {isRootAsset ? (
              <img
                style={styles.elementIconStyle}
                src={markerUtilities.getInactiveAssetIconFromSchemaMarkerId(
                  workOrderAsset.markerId,
                )}
              />
            ) : (
              iconUtilities.getDataManagementElementIcon(
                null,
                hasPendingChanges,
              )
            )}
            {hasDownstreamChanges && <div styleName="change-indicator icon" />}
          </div>
          <div styleName="row-name-container">
            <div styleName={getRowNameStyle(hasPendingChanges, false)}>
              <span styleName="row-name-text">{workOrderAsset.name}</span>
            </div>
            <div styleName="row-name subtext">
              <TextOverflowTooltip
                text={workOrderAsset.assetType}
                width="100%"
                lightTooltip
              />
            </div>
          </div>
        </div>
        <div styleName="controls-container">
          {showControls && (
            <ElementControls
              // @ts-ignore
              element={workOrderAsset}
            />
          )}
          {showAdditionalOptions && (
            <>
              <Menu>
                <MenuItem
                  startAdornment={
                    <Icon>
                      <img src={addItemIcon} />
                    </Icon>
                  }
                  onClick={() =>
                    setOpenModal({ type: 'ADD_ELEMENT', id: workOrderAsset.id })
                  }
                >
                  Add Sub Items
                </MenuItem>
                <MenuItem
                  startAdornment={<Icon>edit</Icon>}
                  onClick={() =>
                    setOpenModal({ type: 'EDIT', id: workOrderAsset.id })
                  }
                >
                  Edit
                </MenuItem>
              </Menu>
              <AddElementModal
                open={
                  openModal.type === 'ADD_ELEMENT' &&
                  openModal.id === workOrderAsset.id
                }
                closeModal={() => setOpenModal({ type: null, id: null })}
                // @ts-ignore
                element={workOrderAsset}
              />
              <RenameModal
                open={
                  openModal.type === 'EDIT' &&
                  openModal.id === workOrderAsset.id
                }
                closeModal={() => setOpenModal({ type: null, id: null })}
                id={workOrderAsset.id}
                name={workOrderAsset.name}
                type="element"
                renameAction={renameElement}
              />
            </>
          )}
        </div>
      </div>
      {expanded ? (
        <>
          {workOrderAsset.elementGroups.map(
            (elementGroup: ElementGroupsTreeItem, index: number) => (
              <ElementGroupRow
                key={`${elementGroup.name}-${index}`}
                elementGroup={elementGroup}
                indentation={1}
              />
            ),
          )}
          {workOrderAsset.elements.map(
            (element: WorkOrderAssetTreeElement, index: number) => (
              <ElementRow
                key={`${element.name}-${index}`}
                element={element}
                indentation={1}
              />
            ),
          )}
        </>
      ) : (
        <div />
      )}
    </>
  );
};

export default WorkOrderAssetTree;
