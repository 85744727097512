import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { SortDirection } from '@mui/material';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
import {
  CREATE_BUDGET,
  DELETE_BUDGET,
  DUPLICATE_BUDGET,
  GET_BUDGET_TEMPLATES,
  GET_BUDGETS,
  UPDATE_BUDGET,
} from '@atom/graph/budget';
import { Snackbar } from '@atom/mui';
import {
  Budget,
  BudgetCreateInput,
  BudgetDuplicateInput,
  BudgetModal,
  BudgetsConnection,
  BudgetsConnectionInput,
  BudgetTemplatesConnection,
  BudgetUpdateInput,
} from '@atom/types/budget';
import history from '@atom/utilities/history';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetsFormModal from './budgetsModal/BudgetsFormModal';
import BudgetsContext, {
  INITIAL_LIMIT,
  INITIAL_PAGE,
  INITIAL_SORT_BY,
  INITIAL_SORT_DIRECTION,
} from './BudgetsContext';
import BudgetsHeader from './BudgetsHeader';
import BudgetsTable from './BudgetsTable';

import './budgets.css';

const BudgetsPortal = () => {
  const [page, setPage] = useState<number>(INITIAL_PAGE);
  const [limit, setLimit] = useState<number>(INITIAL_LIMIT);
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    INITIAL_SORT_DIRECTION,
  );
  const [sortBy, setSortBy] = useState<string>(INITIAL_SORT_BY);
  const [activeModal, setActiveModal] = useState<BudgetModal>(null);
  const [activeFormBudget, setActiveFormBudget] = useState<Budget>(null);

  const handleModalClose = () => {
    setActiveModal(null);
    setActiveFormBudget(null);
  };

  const { data, loading: loadingBudgets, refetch } = useQuery<
    { budgets: BudgetsConnection },
    { input: BudgetsConnectionInput }
  >(GET_BUDGETS, {
    variables: {
      input: {
        page,
        limit,
        sortBy: isNilOrEmpty(sortBy) ? null : `${sortBy},${sortDirection}`,
      },
    },
    fetchPolicy: 'network-only',
  });

  const { data: templatesData, loading: loadingTemplates } = useQuery<{
    budgetTemplates: BudgetTemplatesConnection;
  }>(GET_BUDGET_TEMPLATES);

  const templates = R.pathOr(
    [],
    ['budgetTemplates', 'budgetTemplates'],
    templatesData,
  );

  const [createBudget, { loading: loadingCreate }] = useMutation<
    { budgetCreate: Budget },
    { input: BudgetCreateInput }
  >(CREATE_BUDGET);

  const [updateBudget, { loading: loadingBudget }] = useMutation<
    { budgetUpdate: Budget },
    { input: BudgetUpdateInput }
  >(UPDATE_BUDGET);

  const [duplicateBudget, { loading: loadingDuplicate }] = useMutation<
    { budgetDuplicate: Budget },
    { input: BudgetDuplicateInput }
  >(DUPLICATE_BUDGET);

  const [deleteBudget, { loading: loadingDelete }] = useMutation<
    {},
    { id: string }
  >(DELETE_BUDGET);

  const handleDuplicateBudget = async (input: BudgetDuplicateInput) => {
    try {
      const newBudget = await duplicateBudget({
        variables: {
          input,
        },
      });
      Snackbar.info({
        message: `Created budget "${input.name}"`,
      });
      const newId = R.pathOr(
        null,
        ['data', 'budgetDuplicate', 'id'],
        newBudget,
      );
      if (isNilOrEmpty(newId)) {
        refetch();
      } else {
        history.push(`budget/${newId}`);
      }
    } catch (error) {
      Snackbar.error({
        message:
          'An error occurred while duplicating your budget. Please Try Again.',
      });
    } finally {
      handleModalClose();
    }
  };

  const handleCreateBudget = async (input: BudgetCreateInput) => {
    try {
      const newBudget = await createBudget({
        variables: {
          input,
        },
      });
      Snackbar.info({
        message: `Created budget "${input.name}"`,
      });
      const newId = R.pathOr(null, ['data', 'budgetCreate', 'id'], newBudget);
      if (isNilOrEmpty(newId)) {
        refetch();
      } else {
        history.push(`budget/${newId}`);
      }
    } catch (error) {
      Snackbar.error({
        message:
          'An error occurred while creating your budget. Please Try Again.',
      });
      handleModalClose();
    }
  };

  const handleUpdateBudget = async (input: BudgetUpdateInput) => {
    try {
      await updateBudget({
        variables: {
          input,
        },
      });
      Snackbar.info({
        message: `Updated budget "${input.name}"`,
      });
      refetch();
    } catch (error) {
      Snackbar.error({
        message:
          'An error occurred while updating your budget. Please Try Again.',
      });
    } finally {
      handleModalClose();
    }
  };

  const handleDeleteBudget = async (budget: Budget) => {
    try {
      await deleteBudget({ variables: { id: budget.id } });
      Snackbar.info({
        message: `Deleted budget "${budget.name}"`,
      });
      refetch();
    } catch {
      Snackbar.error({
        message: `An error occurred while deleting ${budget.name}. Please Try Again.`,
      });
    } finally {
      handleModalClose();
    }
  };

  const budgets: Budget[] = data?.budgets?.budgets || [];
  const totalCount = data?.budgets?.totalCount || 0;
  const budgetNamesUsed = useMemo(
    () => new Set(budgets.map(budget => budget.name)),
    [budgets],
  );

  const modalOpen = R.includes(activeModal, [
    BudgetModal.CREATE,
    BudgetModal.EDIT,
    BudgetModal.DUPLICATE,
  ]);

  return (
    <BudgetsContext.Provider
      value={{
        budgets,
        refetch,
        totalCount,
        page,
        setPage,
        limit,
        setLimit,
        sortDirection,
        setSortDirection,
        sortBy,
        setSortBy,
        loadingBudgets: loadingBudgets || loadingTemplates,
        setActiveModal,
        setActiveFormBudget,
      }}
    >
      <>
        <BudgetsHeader />
        <div styleName="container">
          <BudgetsTable />
        </div>
        {modalOpen && (
          <BudgetsFormModal
            onClose={handleModalClose}
            budget={activeFormBudget}
            modalType={activeModal}
            templates={templates}
            budgetNamesUsed={budgetNamesUsed}
            handleCreateBudget={handleCreateBudget}
            handleUpdateBudget={handleUpdateBudget}
            handleDuplicateBudget={handleDuplicateBudget}
            saving={loadingBudget || loadingCreate || loadingDuplicate}
          />
        )}
        <DeleteModal
          open={activeModal === BudgetModal.DELETE}
          title="Delete Budget?"
          content={`Are you sure you want to delete budget "${activeFormBudget?.name}"?`}
          onCancel={handleModalClose}
          onConfirm={() => handleDeleteBudget(activeFormBudget)}
          disabled={loadingDelete}
        />
      </>
    </BudgetsContext.Provider>
  );
};

export default BudgetsPortal;
