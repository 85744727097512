import React from 'react';

import { Select } from '@atom/mui';

const { MenuItem } = Select;

interface Props {
  id: string;
  value: any;
  onChange: (value: any) => void;
  options?: string[];
  disabled: boolean;
}

const EnumSingleInput = ({
  id,
  value,
  onChange,
  options = [],
  disabled,
}: Props) => {
  const handleChange = event => {
    onChange(event.target.value);
  };

  return (
    <Select
      key={id}
      fullWidth
      onChange={handleChange}
      value={value || ''}
      disabled={disabled}
    >
      {options.map((option: string, index: number) => (
        <MenuItem key={index} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};

export default EnumSingleInput;
