import React from 'react';

import { TextField } from '@atom/mui';

interface Props {
  id: string;
  value: any;
  onChange: (value: any) => void;
  disabled: boolean;
}

const NumberInput = ({ id, value, onChange, disabled }: Props) => {
  const handleChange = (number: string) => {
    onChange(number ? parseFloat(number) : '');
  };

  return (
    <TextField
      key={id}
      type="number"
      value={value || ''}
      onChange={event => handleChange(event.target.value)}
      disabled={disabled}
    />
  );
};

export default NumberInput;
